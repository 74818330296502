import React, { Suspense, useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import Loader, { PageLoader } from "../components/loader/loader";
import TopNavbar from "./topNavbar";
import { UserContext } from "../context/theme";
import Header from "./header";
import Footer from "./footer";
import "react-toastify/dist/ReactToastify.css";
import { socketConnectionURL } from "../services/api-url";
import io from "socket.io-client";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";


const Dashboard = React.lazy(() => import("../pages/dashboard"));
const Notifications = React.lazy(() => import("../pages/notifications"));
const SliderList = React.lazy(() => import("../pages/slider/list"));
const SliderAdd = React.lazy(() => import("../pages/slider/add"));
const SliderEdit = React.lazy(() => import("../pages/slider/edit"));
const ProductCategoryList = React.lazy(() => import("../pages/master/productCategory/list"));
const AddProductCategory = React.lazy(() => import("../pages/master/productCategory/add"));
const EditProductCategory = React.lazy(() => import("../pages/master/productCategory/edit"));
const ProductSubCategoryList = React.lazy(() => import("../pages/master/productSubCategory/list"));
const ProductSubCategoryAdd = React.lazy(() => import("../pages/master/productSubCategory/add"));
const ProductSubCategoryEdit = React.lazy(() => import("../pages/master/productSubCategory/edit"));
const GstList = React.lazy(() => import("../pages/gst/list"));

const UnitList = React.lazy(() => import("../pages/master/units/list"));
const UnitAdd = React.lazy(() => import("../pages/master/units/add"));
const UnitEdit = React.lazy(() => import("../pages/master/units/edit"));


const GSTAdd = React.lazy(() => import("../pages/gst/add"));
const GSTEdit = React.lazy(() => import("../pages/gst/edit"));
const PromoList = React.lazy(() => import("../pages/promo/list"));
const PromoAdd = React.lazy(() => import("../pages/promo/addPromo"));
const BulkEnquiryList = React.lazy(() => import("../pages/bulkEnquiry/list"));
const Faqs = React.lazy(() => import("../pages/faqs/list"));
const FaqsAdd = React.lazy(() => import("../pages/faqs/add"));
const FaqsEdit = React.lazy(() => import("../pages/faqs/edit"));
const Vendor = React.lazy(() => import("../pages/vendor/list"));
const VendorProductList = React.lazy(() => import("../pages/products/list"));
const VendorProductView = React.lazy(() => import("../pages/products/view"));
const VendorRequestList = React.lazy(() => import("../pages/products/requests"));
const VendorStoreRequestList = React.lazy(() => import("../pages/products/requestStore"));
const VendorStoreRequestListView = React.lazy(() => import("../pages/products/requestStore-view"));
const Customer = React.lazy(() => import("../pages/customer/list"));
const Settings = React.lazy(() => import("../pages/global/settings"));
const Versions = React.lazy(() => import("../pages/global/versions"));
const ChangePassword = React.lazy(() => import("../pages/global/change-password"));
const Contact = React.lazy(() => import("../pages/contact/list"));
const Terms = React.lazy(() => import("../pages/information/terms"));
const Privacy = React.lazy(() => import("../pages/information/privacy"));
const About = React.lazy(() => import("../pages/information/about"));
const DeliveryNote= React.lazy(() => import("../pages/information/deliveryNote"));
const CustomerProfile = React.lazy(() => import("../pages/customer/customerview"));
const VendorProfile = React.lazy(() => import("../pages/vendor/vendorView"));
const Helpdesk = React.lazy(() => import("../pages/helpdesk/list"));
const HelpdeskView = React.lazy(() => import("../pages/helpdesk/view"));
const SubsCriptionList = React.lazy(() => import("../pages/subscription/list"));
const SubsCriptionAdd = React.lazy(() => import("../pages/subscription/createSubscription"));
const SubsCriptionEdit = React.lazy(() => import("../pages/subscription/edit"));
const SubscriptionOrders = React.lazy(() => import("../pages/subscription/orderList"));
const Notification = React.lazy(() => import("../pages/notifications"));
const OrderList = React.lazy(() => import("../pages/manageOrders/list"));
const OrderView = React.lazy(() => import("../pages/manageOrders/view"));
const Sattlements = React.lazy(() => import("../pages/sattelment/listVendor"));
const ManageAccount = React.lazy(() => import("../pages/manageAccount/list"));
const SearchHistory = React.lazy(() => import("../pages/searchHistory/list"));
const WhatsappInsight = React.lazy(() => import("../pages/whatsappInsight/list"));
const ReviewList = React.lazy(() => import("../pages/review/productReview"));
const TopSpotList = React.lazy(() => import("../pages/topSpot/list"))
const TopSpotAdd = React.lazy(() => import("../pages/topSpot/add"))
const SattlementList = React.lazy(() => import("../pages/sattelment/sattlementList"))
const PreferredVendor = React.lazy(() => import("../pages/vendorPreference/list"))

function Layout() {
    const context = useContext(UserContext);
    const { setSocket, auth } = context;
    const authLocal = localStorage.getItem("token");
    useEffect(() => {
        if (auth && authLocal != undefined) {
            const connection = io(`${socketConnectionURL}?type=admin&token=${authLocal}`, { transports: ["websocket", "polling"] });
            setSocket(connection || {});

            connection.on("connect", () => { });

            connection.on("disconnect", () => { });
            return () => {
                connection.off("connect");
                connection.off("disconnect");
            };
        }
    }, [auth, authLocal]);

    return (
        <div
            className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar ? "mini-sidebar" : ""}      
    `}
        >
            <ToastContainer />
            <Header />
            <div className="all-content-wrapper">
                <TopNavbar />
                <Loader />

                <div className="mainContent px-3 mt-4">
                    <div className="container-fluid">
                        <Suspense fallback={<PageLoader />}>
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/notifications" element={<Notifications />} />

                                <Route path="/faqs/:type/:page" element={<Faqs />} />
                                <Route path="/faqs/:type/add" element={<FaqsAdd />} />
                                <Route path="/faqs/:type/edit/:id" element={<FaqsEdit />} />

                                <Route path="/vendor/:type" element={<Vendor />} />
                                <Route path="/vendor" element={<Vendor />} />
                                <Route path="/vendor/view/:id" element={<VendorProfile />} />
                                <Route path="/store/:type" element={<VendorProductList />} />
                                <Route path="/store/:type/:vendorId" element={<VendorProductList />} />
                                <Route path="/store/view/:type/:productId" element={<VendorProductView />} />
                                <Route path="/store/inquiry/:type" element={<VendorRequestList />} />
                                <Route path="/store/inquiry" element={<VendorStoreRequestList />} />
                                <Route path="/store/inquiry-view/:id" element={<VendorStoreRequestListView />} />
                                <Route path="/store/review/:productId/:page" element={<ReviewList />} />

                                <Route path="/helpdesk/list/:page" element={<Helpdesk />} />
                                <Route path="/helpdesk/view/:ticketId" element={<HelpdeskView />} />


                                <Route path="/:type/unit/:page" element={<UnitList />} />
                                <Route path="/:type/unit/add" element={<UnitAdd />} />
                                <Route path="/:type/unit/edit/:id" element={<UnitEdit />} />


                                <Route path="/customers" element={<Customer />} />

                                <Route path="/settings" element={<Settings />} />
                                <Route path="/version" element={<Versions />} />
                                <Route path="/change-password" element={<ChangePassword />} />
                                <Route path="/contact/:page" element={<Contact />} />
                                <Route path="/bulk-inquiry/:page" element={<BulkEnquiryList />} />
                                <Route path="/information/terms" element={<Terms />} />
                                <Route path="/information/privacy" element={<Privacy />} />
                                <Route path="/information/about" element={<About />} />
                                <Route path="/information/delivery-note" element={<DeliveryNote />} />
                                <Route path="/customers/view/:id" element={<CustomerProfile />} />
                                <Route path="/notifications" element={<Notification />} />
                                <Route path="/:type/category/:page" element={<ProductCategoryList />} />
                                <Route path="/:type/category/add" element={<AddProductCategory />} />
                                <Route path="/:type/category/edit/:id" element={<EditProductCategory />} />
                                <Route path="/:type/sub-category/:page" element={<ProductSubCategoryList />} />
                                <Route path="/:type/sub-category/add" element={<ProductSubCategoryAdd />} />
                                <Route path="/:type/sub-category/edit/:id" element={<ProductSubCategoryEdit />} />
                                <Route path="/:type/slider/:page" element={<SliderList />} />
                                <Route path="/:type/slider/add" element={<SliderAdd />} />
                                <Route path="/:type/slider/edit/:id" element={<SliderEdit />} />
                                <Route path="/gst/:page" element={<GstList />} />
                                <Route path="/gst/add" element={<GSTAdd />} />
                                <Route path="/gst/edit/:id" element={<GSTAdd />} />
                                <Route path="/subscription/:page" element={<SubsCriptionList />} />
                                <Route path="/subscription/add" element={<SubsCriptionAdd />} />
                                <Route path="/subscription/edit/:id" element={<SubsCriptionEdit />} />
                                <Route path="/subscription/orders/:page" element={<SubscriptionOrders />} />
                                <Route path="/promocode/:page" element={<PromoList />} />
                                <Route path="/promocode/add" element={<PromoAdd />} />
                                <Route path="/promocode/edit/:id" element={<PromoAdd />} />
                                <Route path="/manage-orders/orders/:orderId" element={<OrderList />} />
                                <Route path="/manage-orders/orders" element={<OrderList />} />
                                <Route path="/manage-orders/orders/view/:orderId" element={<OrderView />} />
                                <Route path="/preferred/:vendorName/:vendorId/:page" element={<PreferredVendor />} />
                                <Route path="/settlements/:vendorId/:page" element={<Sattlements />} />
                                <Route path="/manage-account/:page" element={<ManageAccount />} />
                                <Route path="/search-history/:page" element={<SearchHistory />} />
                                <Route path="/whatsapp-insight/:page" element={<WhatsappInsight />} />
                                <Route path="/top-spot" element={<TopSpotList />} />
                                <Route path="/top-spot/:vendor" element={<TopSpotList />} />
                                <Route path="/top-spot/add" element={<TopSpotAdd />} />
                                <Route path="/top-spot/add/vendor" element={<TopSpotAdd />} />
                                <Route path="/top-spot/edit/:id" element={<TopSpotAdd />} />
                                {/* <Route path="/settlements/:page" element={<SattlementList />} /> */}

                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Suspense>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Layout;
